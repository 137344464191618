@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    font-family: "Roboto", sans-serif;
  }
}

body,
#root {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  --font-secondary: "Quicksand";
}

.ant-tabs-tab-btn,
.ant-menu-title-content {
  font-family: var(--font-secondary), sans-serif !important;
  font-weight: 700;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  user-select: none;
  font-family: "Roboto", sans-serif;
}

.ant-layout-sider,
.ant-layout-sider-trigger {
  border-right: 0;
}

.leaflet-routing-container {
  display: none !important;
}

body > iframe {
  /* display: none;*/
}
